import React, { useState, useCallback, useEffect } from "react"
import Shell from '../components/global/shell'
import { graphql, Link } from "gatsby"
import Helmet from "react-helmet"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Container from '../components/global/container'
import styled from 'styled-components'
import tw from "twin.macro"
import Sidenav from '../components/tutorials/sidenav'
import {MdAccessTime} from 'react-icons/md'
import EmailSubscription from '../components/global/emailsubscription'
import NewsNotification from "../components/global/newsnotification"

const Wrapper = styled.main`
  ${tw`md:py-10`}
  display: grid;
  grid-template-columns: 100%;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 64% 36%;
    grid-gap: 25px;
  }

  nav {
    position: sticky;
    align-self: start;
    top: 0;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;

    li {
      padding-bottom: 6px;
    }
  }

  .iframe {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-bottom: 20px;
  }

  .iframe iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }

  h2 {
    ${tw`font-bold text-2xl pt-6 pb-2`}
  }

  h3 {
    ${tw`font-bold text-lg pt-4`}
  }

  p {
    ${tw`pb-5 pt-2 text-base md:text-lg`}
  }

  
  blockquote {
    ${tw`border-l-4 border-yellow-300 bg-yellow-50 my-6 p-4`};
  }

  a {
    ${tw`text-gray-700 bg-yellow-50 hover:bg-yellow-100 border-b border-yellow-300`}
  }


  code[class*="language-text"] {
    /* color: #fff; */
    ${tw`bg-gray-800 text-gray-50`}
    text-shadow: none;

    span {
      background: none;
    }
  }

  .gatsby-resp-image-wrapper {
    ${tw`my-4 shadow`}
  }

  .gatsby-highlight {
    ${tw`rounded-lg text-base bg-gray-800`}


    pre {
      ${tw`rounded-lg text-base leading-snug  bg-gray-800 `}
      
      code[class*="language-r"] {
        color: #fff;
        text-shadow: none;
        ${tw`text-sm leading-snug bg-gray-800`}

        span {
          background: none;

          &.gatsby-highlight-code-line {
            ${tw`rounded-lg text-sm leading-snug bg-gray-700 rounded-none pl-4 -ml-4 -mr-4`}
            display: block;
          }
        }
      }
    }
  }
`;


const Tutorial = (props) => {
  // console.log(props);

  const tutData = props.data.visualizations;

  const [ref, setRef] = useState(null);
  const [activeHeading, setActiveHeading] = useState(null);
  const [jumpedHeading, setJumpedHeading] = useState(null);
  
  // Intersection observer
  useEffect(() => {
    if (ref) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setActiveHeading(entry.target.innerHTML);
        }
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 1
      });
      ref.querySelectorAll('h1, h2, h3').forEach(section => {
        observer.observe(section);
      })
   
    }
  }, [ref]);

  // Trigger new render when maincontent changes
  const onRefChange = useCallback(node => {
    setRef(node);
  });

  // Scroll to heading when user clicks nav
  useEffect(() => {
    if (ref) {
      ref.querySelectorAll('h1, h2, h3').forEach(section => {
        if (section.innerHTML === jumpedHeading) {
          section.scrollIntoView();
        }
      })
    }
  }, [jumpedHeading])

  return (
    <Shell>
      <Helmet>
        <title>{tutData.frontmatter.title}</title>
      </Helmet>

      {/* <div className='fixed top-0 left-0 w-full h-full z-0  bg-cblue' /> */}
      {/* <div className='border-t-8 border-yellow-200 sticky top-0 z-40' /> */}
      <div className='py-10 pb-10 bg-gray-900'>
        <Container>
          <div className='lg:w-2/3'>
            <h1 className='font-bold text-2xl md:text-4xl lg:text-5xl pt-6 pb-2 text-yellow-200 leading-12'>{tutData.frontmatter.title}</h1>
            <p className='text-white text-lg md:text-xl lg:text-2xl mb-8 lg:leading-12'>{tutData.frontmatter.description}</p>
            <time className='text-sm text-gray-400 flex items-center'>
              {/* <MdAccessTime className='mr-2 text-yellow-300'/> */}
              Last update: {tutData.frontmatter.date}</time>
          </div>
        </Container>
      </div>


      <div className='w-full h-auto'>
        <Container>
          <Wrapper className=''>
            <div className='' ref={onRefChange}>
              <MDXRenderer images={tutData.frontmatter.images}>
                {tutData.body}
              </MDXRenderer>
            </div>

            <nav className="invisible lg:visible pt-6 px-6 ">
              <Sidenav content={ref} active={activeHeading} setJumpedHeading={setJumpedHeading}/>
            </nav>

          </Wrapper>
          <EmailSubscription />
        </Container>
      </div>

      <NewsNotification />

    </Shell>
  )
}

export default Tutorial;


export const query = graphql`
  query($slug: String!) {
    visualizations: mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        description
        date(formatString: "MMMM Do, YYYY")
        thumbnail {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 525)
          }
        }
        images {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1525)
          }
        }
      }
    }
  }
`