import React from 'react';

export default function SideNav(props) {
  var elementList = null;

  if (props.content) {
    let nodes = props.content.querySelectorAll('h1, h2, h3');

    elementList = [];

    nodes.forEach((e, i) => {
      elementList.push(<li key={i} className={`cursor-pointer pb-1 
      ${e.innerHTML === props.active ? ' text-gray-600' : 'text-gray-300'} hover:text-gray-400 ${e.nodeName === 'H3' ? 'pl-4' : ''}`} 
        onClick={e => props.setJumpedHeading(e.currentTarget.innerHTML)}>{e.innerHTML}</li>);
    })
  }

  return (
    <div className=''> 
      <ul>
        {elementList}
      </ul>
    </div>
  )
}
