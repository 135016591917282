import React, { Component } from 'react';

class EMail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formEmail: ""
    }

    this.handleFormInput = this.handleFormInput.bind(this);
  }

  render() {
    return (
      <div className='md:py-10'>
        <div id="mc_embed_signup" className='bg-gradient-to-br from-yellow-200 to-yellow-400 md:w-2/3 p-4 my-10 rounded text-base text-gray-700 border-t-8 border-yellow-400'>

          <form action="https://gmail.us20.list-manage.com/subscribe/post?u=2c738a6289f06aedaf9dbe04b&amp;id=b87566f666" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate my-2 p-2" target="_blank" noValidate>

            <div>
              <label htmlFor="mce-EMAIL" className='text-left text-lg'>Learn more about ggplot2!</label>

              <div className='flex flex-col md:flex-row my-5'>
                
                <input type="email" value={this.state.formEmail}
                  onChange={this.handleFormInput}
                  name="EMAIL" className="text-left p-2 md:p-2 md:text-lg h-16 rounded max-w-100 w-100 bg-yellow-50" id="mce-EMAIL" placeholder="email address" required></input>

                <input type="submit" value="Subscribe" name="subscribe" className="md:h-16 bg-gray-800 text-gray-200 rounded p-2 mt-5 md:mt-0 md:ml-2 cursor-pointer hover:bg-gray-900" />
              </div>

              
              <div style={{position: `absolute`, left: `-5000px`}} aria-hidden="true">
                <input type="text" name="b_2c738a6289f06aedaf9dbe04b_b87566f666" tabIndex="-1"
                onChange={this.handleFormInput} value={this.state.formEmail} />
              </div>

              <p className='text-left text-sm md:text-lg md:w-2/3 my-5'>Sign up to receive updates on new tutorials and features. If you cannot find the e-mail, check your spam folder.</p>
            </div>

          </form>
        </div>
      </div>
    );
  }

  handleFormInput(event) {
    this.setState({formEmail: event.target.value});
  }
}

export default EMail;